import "../styles/globals.css";
//import "../styles/code.css";
//import "../styles/resizer.css";
//import "../styles/docsearch.css";
//import "../styles/tour.css";
import "../styles/toast.css";
import "../styles/json_view.css";
//import "intro.js/introjs.css";
//import "@react-pdf-viewer/core/lib/styles/index.css";
//import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-balham.css";
import "ag-grid-enterprise";



import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { Provider, useDispatch } from "react-redux";
import { DefaultSeo } from "next-seo";


import { ProgressBar } from "../components/ProgressBar";
import ResizeListener from "../components/ResizeListener";

// import your default seo configuration
import SEO from "../../seo.config";

import { persistor, store } from "../features/store";

import { inter, lexend, mono, roboto } from "../managers/fontLoader";
import Portal from "../components/Portal";

import FullPageLoadingIndicator from "../components/FullPageLoadingIndicator";



function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (

      <Provider store={store}>
        <PersistGate
          loading={<FullPageLoadingIndicator />}
          persistor={persistor}
        >
          <div
            id="__app"
            className={`w-full ${inter.variable} ${lexend.variable}  ${roboto.variable} overflow-hidden`}
          >
            <ProgressBar />
            <DefaultSeo {...SEO} />
            <ToastContainer
              position="top-center"
              hideProgressBar={true}
              closeOnClick
              pauseOnHover
            />
            <ResizeListener />
            <Portal></Portal>
            <div id="portal"></div>

            {getLayout(<Component {...pageProps} />)}
          </div>
        </PersistGate>
      </Provider>
  );
}

export default MyApp;
//
